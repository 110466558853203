import { lazy } from 'react';


export default [
  { path: '/', element: lazy(() => import('containers/pages/Main')), external: false },
  { path: '/login', element: lazy(() => import('containers/pages/Login')), external: true },
  { path: '/reset-password', element: lazy(() => import('containers/pages/ResetPassword')), external: true },
  { path: '/personal/*', element: lazy(() => import('containers/pages/Personal')), external: false },
  { path: '/preview', element: lazy(() => import('containers/pages/Preview')), external: false },
  { path: '/registration/requests', element: lazy(() => import('containers/pages/Registration')), external: true },
];
