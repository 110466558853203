import { t } from 'i18next';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { uploadFileApi } from 'store/common/api';
import { setToken } from 'store/common/utils';
import { abortController } from 'store/utils';

import {
  loginApi,
  logoutApi,
  forgotPasswordApi,
  resetPasswordApi,
  userApi,
  validateRegistrationUrlApi,
  validateEmailApi,
  registrationRequestApi,
  changePasswordApi,
  userUpdateApi,
} from './api';


const authToken = window.localStorage.getItem('token');

export default create(devtools(
  immer((set) => abortController({
    token: authToken,
    user: {},
    errors: {
      auth: '',
      forgot: '',
      reset: '',
      logout: '',
    },

    logout: async (payload, { setState }) => {
      try {
        await logoutApi();
      } catch (error) {
        setState(({ errors }) => {
          errors.logout = error?.response?.data?.errors;
        }, 'error');

        throw new Error(error?.response?.data?.errors);
      }

      localStorage.setItem('token', '');
      localStorage.setItem('role', undefined);
      window.location.href = '/';

      setState({ token: false });
    },

    login: async ({ email, password }, { setState }) => {
      try {
        const { data: { token } } = await loginApi({ email, password });

        window.localStorage.setItem('token', token);
        setToken(`Bearer ${token}`);

        setState((state) => {
          state.token = token;
          state.errors.auth = '';
        }, 'fulfilled');
      } catch (error) {
        setState(({ errors }) => {
          errors.auth = t('common.authError');
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

    getUser: async (payload, { signal, setState }) => {
      try {
        const { data: user } = await userApi(signal);
        localStorage.setItem('role', user.role);

        setState({ user }, 'fulfilled');

        return user;
      } catch (error) {
        throw new Error(error);
      }
    },

    updateUser: async (payload, { setState }) => {
      try {
        const { data: user } = await userUpdateApi(payload);
        setState({ user }, 'fulfilled');
        setState(({ errors }) => { errors.user = ''; }, 'fulfilled');
        return user;
      } catch (error) {
        setState(({ errors }) => {
          errors.user = { ...error.response.data.errors };
        }, 'error');
        throw new Error(error);
      }
    },

    forgotPassword: async (email, { setState }) => {
      try {
        const { data } = await forgotPasswordApi({ email });

        setState(({ errors }) => { errors.forgot = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.forgot = error.response.data.errors;
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

    resetPassword: async (payload, { setState }) => {
      try {
        const { data } = await resetPasswordApi(payload);

        setState(({ errors }) => { errors.reset = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.reset = error.response.data.errors;
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

    validateRegistrationUrl: async ({ companyId, token }, { setState }) => {
      try {
        const { data } = await validateRegistrationUrlApi(companyId, token); // TODO перенести в companies после их перевода на zustand

        setState(({ errors }) => { errors.validateRegistration = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.validateRegistration = error.response.data.message;
        }, 'error');

        throw new Error(error.response.data.message);
      }
    },

    validateEmail: async (payload, { setState }) => {
      try {
        const { data } = await validateEmailApi(payload); // TODO перенести в companies после их перевода на zustand

        if (data.isAvailable) {
          setState(({ errors }) => { errors.validateEmail = ''; }, 'fulfilled');
        } else {
          setState(({ errors }) => {
            errors.validateEmail = 'emailIsNotAvailable';
            throw new Error('emailIsNotAvailable');
          }, 'error');
        }

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.validateEmail = error?.message;
        }, 'error');

        throw error?.message;
      }
    },

    registrationRequest: async (payload, { setState }) => {
      try {
        let avatarId;
        if (payload?.photo?.file) {
          const form = new FormData();
          form.append('file', payload?.photo?.file);
          const { data: { fileId } } = await uploadFileApi(form);
          avatarId = fileId;
        }

        const { data } = await registrationRequestApi({ ...payload, avatarId }); // TODO перенести в companies после их перевода на zustand

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.registrationRequests = error.response.data.errors;
        }, 'error');

        throw error.response.data.errors;
      }
    },

    changePassword: async (payload, { setState }) => {
      try {
        const { data } = await changePasswordApi(payload);

        setState(({ errors }) => { errors.change = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.change = error.response.data.errors;
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

  }, set, 'Auth')),
  {
    name: 'Auth',
  },
));

setToken(`Bearer ${authToken}`);
