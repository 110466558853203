import { useCallback, useState } from 'react';
import { Status, } from './utils.types';
const zustandAbortWrapper = (val, setState) => (payload) => {
    const ac = new AbortController();
    return ({
        abort: ac.abort.bind(ac),
        promise: val(payload, { signal: ac.signal, setState: setState(payload) }),
    });
};
export const abortController = (object, set, name) => (Object.entries(object).map(([key, val]) => {
    if (typeof val === 'function') {
        return [
            key,
            zustandAbortWrapper(val, (payload) => (data, more) => set(data, false, { type: `${name}/${key}${more ? `/${more}` : ''}`, payload })),
        ];
    }
    return [key, val];
}).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {}));
export const useStatus = (fn, idleTimeout) => {
    const [status, setStatus] = useState(Status.Idle);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const func = useCallback((payload) => {
        setStatus(Status.Pending);
        const next = fn(payload);
        next
            .promise
            .then((res) => {
            setData(res);
            setError(undefined);
            setStatus(Status.Fulfilled);
            if (idleTimeout)
                setTimeout(setStatus, idleTimeout, Status.Idle);
        })
            .catch((err) => {
            setError(err);
            setData(undefined);
            setStatus(Status.Error);
        })
            .finally(() => {
            setStatus((state) => (state === Status.Pending ? Status.Error : state));
        });
        return next;
    }, [fn, idleTimeout]);
    return {
        fn: func,
        isPending: status === Status.Pending,
        isFulfilled: status === Status.Fulfilled,
        isError: status === Status.Error,
        status,
        data,
        error,
    };
};
