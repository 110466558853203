/* eslint-disable no-new */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { load } from '@2gis/mapgl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import WS from 'store/common/ws';

import routes from 'router';
import store from 'store';

import './i18n';
import './index.scss';
import RecaptchaProvider from 'components/ReCaptchaProvider/ReCaptchaProvider';


dayjs.extend(utc);

new WS();

console.log(process.env.npm_package_version); // eslint-disable-line

load().then(() => {
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {routes.map(
            ({ path, element: Element, external }) => (
              <Route
                key={path}
                path={path}
                element={(
                  <RecaptchaProvider external={external}>
                    <Element />
                  </RecaptchaProvider>
                )}
              />
            ),
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={false}
        limit={3}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>,
  );
});
