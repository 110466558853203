import request from '../common/utils';


export const loginApi = ({ email, password }) => request({
  url: '/login',
  method: 'POST',
  data: { email, password },
});

export const logoutApi = () => request({
  url: '/logout',
  method: 'POST',
});

export const userApi = (signal) => request({
  url: '/me',
  method: 'GET',
  signal,
});

export const userUpdateApi = (data) => request({
  url: '/me',
  method: 'put',
  data,
});

export const forgotPasswordApi = (data) => request({
  url: '/forgot-password',
  method: 'POST',
  data,
});

export const resetPasswordApi = (data) => request({
  url: '/reset-password',
  method: 'POST',
  data,
});

export const validateRegistrationUrlApi = (companyId, token) => request({ // TODO перенести в companies после их перевода на zustand
  url: `/companies/${companyId}/registration/info?token=${token}`,
  method: 'GET',
});

export const validateEmailApi = (data) => request({ // TODO перенести в companies после их перевода на zustand
  url: '/check-email-availability',
  method: 'POST',
  data,
});

export const registrationRequestApi = ({ companyId, ...rest }) => request({ // TODO перенести в companies после их перевода на zustand
  url: `/companies/${companyId}/registration/requests`,
  method: 'POST',
  data: rest,
});

export const changePasswordApi = (data) => request({
  url: '/changePassword',
  method: 'post',
  data,
});
